@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "EasyGrotesk";
    src: url("./assets/fonts/EasyGrotesk/Easy-Grotesk-Regular.ttf") format("truetype");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "EasyGrotesk";
    font-weight: 600;
    src: url("./assets/fonts/EasyGrotesk/Easy-Grotesk-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "EasyGrotesk";
    font-weight: 700;
    src: url("./assets/fonts/EasyGrotesk/Easy-Grotesk-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "EasyGrotesk";
    font-weight: 800;
    src: url("./assets/fonts/EasyGrotesk/Easy-Grotesk-ExtraBold.ttf") format("truetype");
  }

  @layer base {
    body {
      @apply font-easyGrotesk;
    }
  }

  .team-avatar img {
    transition: opacity 0.3s ease-in-out;
  }
  
  .team-avatar img.hover-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .team-avatar:hover img.hover-image {
    opacity: 1;
  }
  
  .team-avatar:hover img.default-image {
    opacity: 0;
  }